@import "variables"

/* You can add global styles to this file, and also import other style files */
@font-face
  font-family: "Suisse Int'l"
  font-weight: 300
  src: url("assets/fonts/SuisseIntl-Light.otf")

@font-face
  font-family: "Suisse Int'l"
  font-weight: 700
  src: url("assets/fonts/SuisseIntl-Bold.otf")

@font-face
  font-family: "Suisse Works"
  font-weight: 700
  src: url("assets/fonts/SuisseWorks-Bold.otf")

html
  scroll-behavior: smooth
  font-size 1.2vw
body

  *
    box-sizing border-box
    &::-webkit-scrollbar
      display: none
    &:focus
      outline: none
  img
    width 100%
    line-height: 0
  margin: 0
  width: 100%
  font-family: "Suisse Int'l", sans-serif

@media (min-width: $large-breakpoint)
  html
    font-size 18px

@media (min-width: $small-breakpoint)
  .desktop-hidden
    display none !important

@media (max-width: $small-breakpoint)
  .mobile-hidden
    display none !important
  html
    font-size 5vw


.image
  line-height: 0

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li
  margin: 0
  padding: 0

ul
  list-style-type: none

.nobreak
  white-space nowrap

p
  font-size: 1rem
  line-height: 1.6
  margin-bottom: 1rem
  letter-spacing .4px
  &.large
    font-size 1.3rem
  a
    color: $mint
    text-decoration: none
    &:hover
      text-decoration: underline


.button
  display: inline-block
  cursor pointer
  padding: 18px 32px
  line-height: 1
  background: $white
  box-shadow: $shadow
  position relative
  transition: $tt
  font-size: 1.5rem
  white-space: nowrap
  perspective: 100px
  text-decoration: none
  &::after
    content: ""
    position: absolute
    bottom: 0
    background: $mint
    z-index: 2
    width: 100%
    height: 0
    transition: $tt
    left: 0
  .text,
  .button-text
    text-align: center
    position relative
    z-index: 10
    transition: $tt
    text-decoration: none
    .one
      color: $mint
      transition: $tt
      position relative
      width: 100%
    .two
      color: $white
      transition: $tt
      position absolute
      top: 0
      transform: translate(0, 30px) rotateX(-90deg)
      opacity 0
      width: 100%
  &:hover
    box-shadow: 0 0 30px alpha($mint, 0.5)
    .text,
    .button-text
      .one
        transform: translate(0, -30px) rotateX(90deg)
        opacity: 0
      .two
        transform: translate(0, 0) rotateX(0)
        opacity 1
    &::after
      height: 100%
